<script setup lang="ts">
const { $uniqueRouteName } = useNuxtApp();
const { refreshAds } = useGptStore();

onMounted(() => {
  refreshAds();
});

const { client } = import.meta;
</script>

<template>
  <div class="hay-layout--one-column">
    <div class="hay-layout__full">
      <AdvertSlot
        v-if="client"
        :id="`${$uniqueRouteName()}-topBillboard`"
        unit="topBillboard"
        class="hay-layout__advert--top"
      />
      <slot name="full" />
    </div>
    <div class="hay-layout__content">
      <main class="hay-layout__main">
        <NuxtErrorBoundary>
          <slot />
          <template #error="{ error }">
            <ErrorMessage v-if="error" class="error__message" :error="error" />
            <PageSection title="Recent Ads" :class="$buildClass('recent')">
              <RecentPosts />
            </PageSection>
          </template>
        </NuxtErrorBoundary>
      </main>
    </div>
  </div>
</template>

<style scoped lang="scss">
.hay-layout {
  $this: &;
  &--one-column {
    display: flex;
    flex-direction: column;

    @extend .hay-layout;
    #{$this}__content {
      @include container;

      margin-top: var(--content-top-margin, 0);
      padding-bottom: 16px;
      padding-top: 16px;
    }
    #{$this}__main {
      overflow: hidden;
    }
  }
  &__advert--top {
    @include media-breakpoint-up(md) {
      padding: 32px 16px;
    }

    padding: 16px;

    display: flex;

    justify-content: center;
  }
}
</style>
